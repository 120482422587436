import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-supportpdf-viewer',
  templateUrl: './supportpdf-viewer.component.html',
  styleUrls: ['./supportpdf-viewer.component.scss']
})
export class SupportpdfViewerComponent implements OnInit {
  srcFile:any;
  pdfUrl:any;
  isImgBoolean:boolean;
   zoom: number = 1;
   zoomdata: number = 0.7;
   isUpdated:boolean = false;
  constructor(
    private d_ref :MatDialogRef<SupportpdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.data.file.includes('assets')){
      this.pdfUrl = this.data.file;
      this.srcFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
      this.isUpdated = true;
    } else {
      this.srcFile = this.data.file;
      this.isUpdated = false;
    }
    // this.srcFile = this.data.file;
    console.log(this.srcFile);
  }

}
