<section class="maxPage">
    <div class="d-flex justify-content-end">
        <i class="fa fa-close" style="cursor: pointer" mat-dialog-close></i>
    </div>
    <div *ngIf="isImgBoolean" class="canvasConatiner_height">
      <div id="parentDiv" class="docContaner">
        <img [src]="srcFile" id="canvas1" style="height: 87vh">
      </div>
      <!-- <span class="zoom zoom_pos_img">
            <span class="zoom-in" (click)="zoomin()"
              ><i class="fa fa-plus" aria-hidden="true"></i></span
            ><br />
            <span class="zoom-out" (click)="zoomout()"
              ><i class="fa fa-minus" aria-hidden="true"></i
            ></span>
          </span> -->
    </div>
    <div *ngIf="!isImgBoolean" style="position: relative" class="docContaner">
      <div *ngIf="!isUpdated">
          <pdf-viewer
            #pdfviewer
            [src]="srcFile"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [original-size]="false"
            [fit-to-page]="true"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [zoom]="zoomdata"
            [fit-to-page]="true"
            [render-text]="true"
            [render-text-mode]="2"
          >
          </pdf-viewer>
      </div>
      <div *ngIf="isUpdated">
          <iframe
          [src]="srcFile"
          [style.transform]="'scale(' + zoom + ')'"
          [style.width]="100 / zoom + '%'"
          class="preview-frame"
        ></iframe>
      </div>
    </div>
    <!-- <div *ngIf="srcFile">
      <iframe
        [src]="srcFile | safeUrl"
        [style.transform]="'scale(' + zoom + ')'"
        [style.width]="100 / zoom + '%'"
        class="preview-frame"
      ></iframe>
    </div> -->
  </section>
