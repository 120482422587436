<!-- upload Invoice -->
<!-- <h6 class="headh6">Upload Section</h6> -->
<div class="row m-0">
  <div class="column1">
    <div *ngIf="!displayTablebool">
      <div class="imgPlaceholder" [ngClass]="dataService.configData.client_name == 'AGI' ?'bg-agi':'bg-default'">
      </div>
      <div [ngClass]="dataService.configData.client_name == 'AGI' ?'agi_div':''">
        <div class="welcomtext">
          <h6 class="f-16 mt-4 text-center">Welcome. Let's get you settled in.</h6>
        </div>
        <div class="f-14 shade_color wlcom_bdy">
          <fun-facts [factsList]="factsList"></fun-facts>
        </div>
      </div>
    </div>

    <div *ngIf="displayTablebool" class="p-2">
      <div class="f-14">Recently Added Details</div>
      <div *ngFor="let obj of uploadInvoicesListData; let ind = index" class="addDetails">
        <i class="fa fa-close closeDetails" (click)="deleteQueue(ind, obj)"></i>
        <div *ngFor="let item of obj|keyvalue">
          <label class="f-12 shade_color mb-0">{{item.key}}</label>
          <div class="f-12 wrap" [ngStyle]="{'width':item?.key?.includes('Name')? '160px':'110px'}">{{item.value}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="column2">
    <div class="f-18 d-flex d_msg">
      Hey <span class="username">{{ userDetails.firstName}}</span>,<img
        src="../../../assets/Serina Assets/new_theme/wave.png"
        alt=""
      />
      Please select the below options to upload the invoice
    </div>
    <div class="tabs_div mr_54 mt-3">
      <ul class="nav nav-tabs nav_sub" id="myTab" role="tablist">
        <li class="nav-item" *ngIf="bothOptBoolean || !isQuickUploadbool">
          <a
            class="nav-link"
            id="ideal-tab"
            data-toggle="tab"
            href="#ideal"
            role="tab"
            aria-controls="ideal"
            aria-selected="true"
            [ngClass]="viewType == 'ideal' ? 'active' : ''"
            (click)="chooseTab('ideal')"
          >
            <div class="d-flex align-items-center ideal_tab_msg">
              <div class="d-flex flex-column align-items-center icon_tag">
                <span class="material-icons icon_span"> upload_file </span>
                Ideal Upload
              </div>
              <div class="shade_color primary_clr f-12 ul_msg">
                We usually recommend uploading one invoice at a time for better
                accuracy
              </div>
            </div>
          </a>
        </li>
        <li
          class="nav-item quick"
          *ngIf="vendorAccess && (bothOptBoolean || isQuickUploadbool)"
          (click)="chooseTab('quick')"
        >
          <a
            class="nav-link"
            id="quick-tab"
            data-toggle="tab"
            href="#quick"
            role="tab"
            aria-controls="quick"
            aria-selected="true"
            [ngClass]="viewType == 'quick' ? 'active' : ''"
          >
            <div class="d-flex align-items-center ideal_tab_msg">
              <div class="d-flex flex-column align-items-center icon_tag">
                <span class="material-icons icon_span"> upload_file </span>
                Quick Upload
              </div>
              <div class="shade_color primary_clr f-12 ul_msg">
                We also support multiple uploads at one go
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content mt-2" id="myTabContent">
      <div
        class="tab-pane pt-2"
        [ngClass]="viewType == 'ideal' ? 'show active' : 'fade'"
        id="ideal"
      >
        <!--Vendor Portal Upload-->
        <form #uploadForm="ngForm" class="d-flex flex-wrap gap">
          <div
            class="selectFilter"
            *ngIf="
              serviceInvoiceAccess && vendorAccess && portal_name == 'customer'
            "
             [ngClass]="{
              'green-border': selectedOption,
              'red-border':  !selectedOption
            }"
          >
            <label class="f-12 mb-0">Select Type</label><br />
            <select
              class="form-control accountSelect f-12"
              [(ngModel)]="selectedOption"
              (change)="selectType($event.target.value)"
              name="inv_type"
            >
              <option value="" style="font-weight: 500">Select type</option>
              <option value="Vendor" >Vendor invoice</option>
              <option value="Service">Service invoice</option>
            </select>
          </div>
          <div class="selectFilter" *ngIf="isCustomerPortal"
            [ngClass]="{
              'green-border': EntityName,
              'red-border':  !EntityName
            }">
            <label class="f-12 mb-0">Select Entity</label>
            <p-autoComplete
              placeholder="Search or select from dropdown"
              id="entity_v"
              (onSelect)="selectEntity($event)"
              [group]="false"
              [suggestions]="filteredEnt"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
              [completeOnFocus]="true"
              [autofocus]="true"
              name="EntityName"
              [(ngModel)]="EntityName"
              required
            >
            </p-autoComplete>
          </div>
           <div *ngIf="isCustomerPortal" class="selectFilter" 
          [ngClass]="{
            'red-border': (EntityName && !vendorName && selectedOption === 'Vendor') || (EntityName && !serviceName && selectedOption === 'Service'),
            'green-border': (vendorName && selectedOption === 'Vendor') || (serviceName && selectedOption === 'Service')
          }">
            <!-- <label class="f-12 mb-0">Select Vendor</label><br /> -->
            <label class="f-12 mb-0">{{
              selectedOption === "Service" ? "Select Service" : "Select Vendor"
            }}</label>
            <!-- select vendor -->
            <p-autoComplete
              placeholder="Search or select from dropdown"
              *ngIf="selectedOption === 'Vendor'"
              id="vendr_c"
              (onSelect)="selectVendorAccount($event)"
              [group]="false"
              [suggestions]="filteredVendors"
              (completeMethod)="filterVendor($event)"
              field="VendorName"
              [dropdown]="true"
              name="vendor"
              [(ngModel)]="vendorName"
              required
              [disabled]="!EntityName"
              [pTooltip]="!EntityName ? 'Select Entity first' : null"
              tooltipPosition="top"
              [autofocus]="true" 
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.VendorName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
            <!-- select service -->
            <p-autoComplete
              placeholder="Search or select from dropdown"
              *ngIf="selectedOption === 'Service'"
              id="service_c"
              (onSelect)="selectService($event.idServiceProvider)"
              [group]="false"
              [suggestions]="filteredService"
              (completeMethod)="filterServices($event)"
              field="ServiceProviderName"
              [dropdown]="true"
              name="service"
              [(ngModel)]="serviceName"
              [disabled]="!EntityName"
              [pTooltip]="!EntityName ? 'Select Entity first' : null"
              tooltipPosition="top"
            >
              <ng-template let-service pTemplate="item">
                <div class="flex align-items-center">
                  <span class="f-12">{{ service.ServiceProviderName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!-- select service account -->
          <div
            class="selectFilter"
            *ngIf="portal_name == 'customer' && selectedOption === 'Service'"
            [ngClass]="{
              'green-border': selectedSAccount,
              'red-border': !selectedSAccount && serviceName
            }"
          >
            <label class="f-12 mb-0">Select Account </label><br />
            <p-autoComplete
              placeholder="Search or select from dropdown"
              (onSelect)="selectServiceAccount($event)"
              [group]="false"
              [suggestions]="filteredServiceAccount"
              (completeMethod)="filterServicesAccount($event)"
              field="Account"
              [dropdown]="true"
              [disabled]="!serviceName"
              [pTooltip]="!serviceName ? 'Select Service' : null"
              tooltipPosition="top"
              name="sAccount"
              [(ngModel)]="selectedSAccount"
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.Account }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div
            *ngIf="selectedOption != 'Service'"
            class="selectFilter"
            [ngClass]="{
              'green-border': selectedInvoiceType || !isCustomerPortal,
              'red-border': !selectedInvoiceType && vendorName
            }"
          >
            <label class="f-12 mb-0">Invoice Type </label><br />
            <select
              class="form-control accountSelect f-12"
              (change)="onSelectPOType($event.target.value, 'ideal')"
              name="invoiceType"
              [(ngModel)]="selectedInvoiceType"
              ngModel
              [value]="invoiceType"
              required
              [disabled]="isCustomerPortal && !vendorName"
              [pTooltip]="isCustomerPortal && !vendorName ? 'Select Vendor' : null"
              tooltipPosition="top"
            >
            <option *ngFor="let item of invTypeArr" [value]="item.value">
              {{ item.name }}
            </option>
            </select>
          </div>
          <div
            *ngIf="
              viewType == 'ideal' &&
              !['non po invoice','multiPO'].includes(selectedInvoiceType) && selectedOption != 'Service'
            "
            class="selectFilter"
            [ngClass]="{
              'green-border': selectedPoNumber,
              'red-border': (!selectedPoNumber && selectedInvoiceType) || (!selectedPoNumber && selectedAccount)
            }"
          >
            <label class="f-12 mb-0">Select PO Number</label>
            <p-autoComplete
              placeholder="Search or select from dropdown"
              (onSelect)="selectedPO($event)"
              [group]="false"
              [forceSelection]="true"
              [suggestions]="filteredPO"
              (completeMethod)="filterPOnumber($event)"
              field="PODocumentID"
              [dropdown]="true"
              name="PONumber"
              [(ngModel)]="selectedPoNumber"
              required
              [disabled]="!selectedInvoiceType && !selectedAccount"
              [pTooltip]="(!selectedInvoiceType && selectedOption != 'Service') ? 'Select Invoice Type' : (!selectedAccount && selectedOption == 'Service') ? 'Select Account' : null"
              tooltipPosition="top"
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.PODocumentID }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div
            *ngIf="
              viewType == 'ideal' &&
              (selectedInvoiceType == 'credit note' && dataService.configData.client_name == 'Cenomi') ||
              (selectedInvoiceType == 'advance invoice' && dataService.configData.client_name == 'SRG')
            "
            class="selectFilter"
            [ngClass]="{
              'green-border': selectedPPType,
              'red-border': !selectedPPType && selectedPoNumber
            }"
          >
            <label class="f-12 mb-0">{{dataService.configData.client_name == 'SRG' ?'Pre-payment type':'Credit type'}}</label>
            <p-dropdown
              [options]="sub_type"
              placeholder="Select type"
              (onChange)="selectedSub($event)"
              name="subType"
              optionLabel="tagName"
              [(ngModel)]="selectedPPType"
              [disabled]="!selectedPoNumber"
              [pTooltip]="!selectedPoNumber ? 'Select PO Number' : null"
              tooltipPosition="top"
            >
            </p-dropdown>
          </div>
          <div class="selectFilter ideal" 
            *ngIf="selectedInvoiceType == 'advance invoice' && dataService.configData.client_name == 'SRG'"
            [ngClass]="{
              'green-border': selectedPPPercentage,
              'red-border': !selectedPPPercentage && selectedPPType
            }"
            >
            <label class="f-12 mb-0">Please add {{pre_type}}</label><br>
            <input type="number" class="form-control accountSelect f-12" 
              (change)="pre_type_value($event.target.value,pre_type)"
              [(ngModel)]="selectedPPPercentage"
              [disabled]="!selectedPPType"
              [pTooltip]="!selectedPPType ? 'Select Pre-Payment Type' : null"
              tooltipPosition="top"
              name="pptype"
            >
          </div>
          <div
            *ngIf="viewType == 'ideal' && selectedInvoiceType == 'credit note' && dataService.configData.client_name == 'SRG' "
            class="selectFilter ideal"
            [ngClass]="{
              'green-border': selectedINVNumber,
              'red-border': !selectedINVNumber && selectedPoNumber
            }"
          >
          <label class="f-12 mb-0">Select Invoice Number</label><br />
          <p-autoComplete
            placeholder="Select Invoice Number"
            (onSelect)="selectedInv($event)"
            [group]="false"
            [forceSelection]="true"
            [suggestions]="filteredInv"
            (completeMethod)="filterInvnumber($event)"
            field="docheaderID"
            [(ngModel)]="selectedINVNumber"
            [dropdown]="true"
            name="invNumber"
            [disabled]="!selectedPoNumber"
            [pTooltip]="!selectedPoNumber ? 'Select PO Number' : null"
            tooltipPosition="top"
          >
          </p-autoComplete>
        </div>
        </form>

        <!-- Upload Option-->
        <div class="mt-3" *ngIf="displaySelectPdfBoolean || reuploadBoolean">
          <div
            *ngIf="isuploadable"
            ng2FileDrop
            [uploader]="uploader"
            [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
            (fileOver)="fileOverBase($event)"
            class="file-upload"
            (onFileDrop)="fileDrop($event)"
            class="dashed-border"
          >
            <div style="margin: 8px" *ngIf="isuploadable">
              <!-- <span class="material-icons f-80"> filter_none </span> -->
              <div class="f-12">
                Drag and drop the invoice (without supporting documents). 
              </div>
              <small class="f-12">Accepted file formats are <strong>.jpeg</strong>, <strong>.png</strong>, or <strong>.pdf</strong>.</small>
              <div class="fileText">Or</div>
              <label for="img" class="img-browse"> Upload </label>
              <input
                ng2FileSelect
                accept=".png, .jpg, .pdf,.jpeg"
                (change)="onSelectFile($event)"
                [uploader]="uploader"
                #fileInput
                type="file"
                id="img"
                name="img"
                hidden
              />
            </div>
          </div>

          <!--Filename dispaly with size-->
          <div
            class="d-flex justify-content-between mr-2 flex-wrap"
            *ngIf="progress || OcrProgress || returnmessage"
          >
            <div class="img-name">{{ name }}</div>
            <div class="f-12">
              <div style="color: #14bb12" *ngIf="OcrProgress && !isError">
                <i class="fa fa-check-o mr-1"></i> Invoice Upload Completed
              </div>
              <div
                *ngIf="progressText == 'Duplicate Invoice Uploaded!'"
                style="color: #ed4040"
              >
                <i class="fa fa-exclamation-triangle mr-1"></i>Upload failed-
                {{ errorMsg }}
              </div>
            </div>
            <!-- <div class="pagerange" *ngIf="selectPageRange">
              <label class="text-center f-12 mb-0"
                >Select Page Of Invoice</label
              >
              <input
                type="text"
                class="form-control accountSelect f-12"
                name="pageLimit"
                [(ngModel)]="inputValue"
                (blur)="changeValue('ideal')"
                (input)="onInputValueChange('ideal')"
                required
                [min]="lowerLimit"
                [max]="upperLimit"
                placeholder="Eg:1-5, 7, 11-15"
                [pattern]="allowedPattern"
                required
              />
            </div> -->
            <div class="d-flex f-12">
              {{ processStage }}
              <span class="cls" aria-hidden="true" (click)="cancelQueue()"
                >&times;</span
              >
            </div>
          </div>

          <!-- <div class="f-12 mt-2 mb-2">
              {{ processStage }} Step - 1/2 Completed.
            </div> -->
          <!-- </div> -->

          <!--Progress bar-->
          <div
            class="f-12 mr-2 d-flex justify-content-between"
            *ngIf="progress"
          >
            <div>
              Uploading
              <span class="f-14">{{ progress }}{{ progressWidth }}%</span>
            </div>
            <div>{{ minutes }}:{{ seconds }}</div>
          </div>
          <div class="progress mr-2" *ngIf="progress">
            <div
              class="progress-bar bg-success"
              [style.width]="progress + '%'"
            ></div>
          </div>
          <div
            class="f-12 percText d-flex justify-content-between mr-2"
            *ngIf="OcrProgress"
            id="percText"
            [ngClass]="isError ? 'duplicate' : 'normal'"
          >
            <div *ngIf="isError">Upload Failed</div>
          </div>
          <div class="progress mb-2 mr-2" *ngIf="OcrProgress">
            <div
              class="progress-bar"
              id="precWidth"
              [ngClass]="isError ? 'bg-red' : 'bg-success'"
              [style.width]="progressWidth + '%'"
            ></div>
          </div>

          <div
            class="f-12 d-flex justify-content-between mr-2"
            *ngIf="OcrProgress"
          >
            {{ progressText }}
            <a class="f-12 pointer" *ngIf="isError" (click)="cancelQueue()">
              Retry Upload
            </a>
          </div>
          <div class="f-12 mb-1" *ngIf="returnmessage && !isError" id="percText">
            Uploading      {{ progressbar }}%
          </div>
          <div
            class="f-12 mb-1 percText d-flex justify-content-between mr-2"
            *ngIf="returnmessage"
            id="percText"
            [ngClass]="isError ? 'duplicate' : 'normal'"
          >
            <div *ngIf="isError">Upload Failed</div>
          </div>
          <div class="progress mb-2" *ngIf="returnmessage">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              id="precWidth"
              [ngClass]="isError ? 'bg-red' : 'bg-success'"
              [style.width]="progressbar + '%'"
            >
              
            </div>
          </div>
          <div
            class="f-12 d-flex justify-content-between mr-2"
            *ngIf="returnmessage"
          >
            {{ progressText }}
            <a class="f-12 pointer" *ngIf="isError" (click)="cancelQueue()">
              Retry Upload
            </a>
          </div>

          <!--buttons-->
          <!-- <div class="text-center" *ngIf="!isuploadable">
                <button
                  type="button"
                  class="btn btnVender bg-btn-cancel mr-2"
                  (click)="cancelQueue()"
                >
                  Cancel
                </button>
                <button
                  class="btn btnVender bg-btn-success"
                  [disabled]="progress || OcrProgress || isButtonDisabled"
                  (click)="selectedOption === 'Vendor' ? uploadInvoice() : uploadService()"
                >
                  Upload
                </button>
              </div> -->
        </div>
      </div>
      <!-- Quick upload Tab -->
      <div
        class="tab-pane pt-2"
        id="quick"
        role="tabpanel"
        aria-labelledby="quick-tab"
        [ngClass]="viewType == 'quick' ? 'show active' : 'fade'"
      >
        <form
          #quickUploadForm="ngForm"
          (ngSubmit)="addInvoiceDetailsToQueue(quickUploadForm.value)"
          class="d-flex flex-wrap gap"
         
        >
          <div class="selectFilter"
          [ngClass]="{
            'green-border': selectedEntityId,
            'red-border':  !selectedEntityId
          }" >
            <label class="f-12 mb-0">Select Entity</label><br />
            <p-autoComplete
              placeholder="Select Entity"
              (onSelect)="selectEntity($event)"
              [group]="false"
              [suggestions]="filteredEnt"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
              [completeOnFocus]="true"
              [autofocus]="true"
              name="EntityName"
              [(ngModel)]="EntityName"
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.EntityName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!--Vendor Selection-->
          <div class="selectFilter"
          [ngClass]="{
            'green-border': selectedVendor,
            'red-border':  !selectedVendor && selectedEntityId
          }">
            <label class="f-12 mb-0">Select Vendor</label><br />
            <p-autoComplete
              placeholder="Select vendor"
              (onSelect)="selectVendorAccount($event)"
              [group]="false"
              [suggestions]="filteredVendors"
              (completeMethod)="filterVendor($event)"
              field="VendorName"
              [dropdown]="true"
              [completeOnFocus]="true"
              name="vendor"
              ngModel
              [(ngModel)]="vendorName"
              [disabled]="!selectedEntityId"
              [pTooltip]="!selectedEntityId ? 'Select Entity first' : null"
              tooltipPosition="top"
              required
              [autofocus]="true" 
              
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.VendorName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!--Select PO type-->
          <div class="selectFilter" 
          [ngClass]="{
            'green-border': selectedInvoiceType_quick,
            'red-border':  !selectedInvoiceType_quick && selectedVendor
          }">
            <label class="f-12 mb-0"
              >Invoice Type
              <i
                *ngIf="this.multiPO_filepath != ''"
                class="fa fa-edit ml-1"
                style="cursor: pointer"
                (click)="editMulti()"
              ></i></label
            ><br />
            <select
              class="form-control accountSelect f-12"
              (change)="onSelectPOType($event.target.value, 'Quick')"
              name="invoiceType"
              ngModel
              [(ngModel)]="selectedInvoiceType_quick"
              required
              [disabled]="!selectedVendor"
              [pTooltip]="!selectedVendor ? 'Select Vendor' : null"
              tooltipPosition="top"
            >
              <option *ngFor="let item of invTypeArr" [value]="item.value">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="selectFilter" *ngIf="LCMBoolean !== 'No'">
            <label class="f-12 mb-0">Select Currency</label>
            <select
              class="form-control accountSelect f-12"
              [(ngModel)]="selectedCurrency"
              name="Currency"
              ngModel
            >
              <option *ngFor="let name of currencyList" [value]="name">
                {{ name }}
              </option>
            </select>
          </div>

          <!-- select PO Number -->
          <div
            class="selectFilter"
            *ngIf="viewType != 'ideal' && selectedInvoiceType_quick != 'non po invoice'"
            [ngClass]="{
              'green-border': slQckPONum,
              'red-border':  !slQckPONum && selectedInvoiceType_quick
            }"
          >
            <label class="f-12 mb-0">Select PO Number</label><br />
            <p-autoComplete
              placeholder="Select PO Number"
              (onSelect)="selectedPO($event)"
              [group]="false"
              [forceSelection]="true"
              [suggestions]="filteredPO"
              (completeMethod)="filterPOnumber($event)"
              field="PODocumentID"
              [dropdown]="true"
              name="PONumber"
              [(ngModel)]="slQckPONum"
              [disabled]="!selectedInvoiceType_quick"
              [pTooltip]="!selectedInvoiceType_quick ? 'Select Invoce Type' : null"
              tooltipPosition="top"
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.PODocumentID }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!-- select Invoice Number -->
          <div
            class="selectFilter"
            *ngIf="
              viewType != 'ideal' &&
              selectedInvoiceType_quick == 'invoice' &&
              selectedCategory == 'returns'
            "
          >
            <label class="f-12 mb-0">Select Invoice Number</label><br />
            <p-autoComplete
              placeholder="Select Invoice Number"
              (onSelect)="selectedInv($event)"
              [group]="false"
              [forceSelection]="true"
              [suggestions]="filteredInv"
              (completeMethod)="filterInvnumber($event)"
              field="docheaderID"
              [dropdown]="true"
              name="PONumber"
              ngModel
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.docheaderID }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!-- select GRN Number -->
          <div
            class="selectFilter"
            *ngIf="
              viewType != 'ideal' &&
              selectedInvoiceType_quick == 'invoice'
            "
            [ngClass]="{
              'green-border': sltGRNNum,
              'red-border':  !sltGRNNum && slQckPONum
            }"
          >
            <label class="f-12 mb-0">Select GRN</label>
            <p-multiSelect
              [options]="po_grn_list"
              placeholder="Number"
              filter="false"
              inputStyleClass="form-control"
              optionLabel="PackingSlip"
              [maxSelectedLabels]="3"
              [selectedItemsLabel]="'{0} items selected'"
              (onChange)="addGrnLine($event)"
              name="PO_GRN_Number"
              [(ngModel)]="sltGRNNum"
              [disabled]="!slQckPONum"
              [pTooltip]="!slQckPONum ? 'Select PO Number' : null"
              tooltipPosition="top"
            >
            </p-multiSelect>
          </div>

          <!-- select GRN Line -->
          <div
            class="selectFilter"
            *ngIf="
              viewType != 'ideal' &&
              selectedInvoiceType_quick == 'invoice'
            "
             [ngClass]="{
              'green-border': PO_GRN_Number_line,
              'red-border':  !PO_GRN_Number_line && sltGRNNum
            }"
          >
            <label class="f-12 mb-0">Select GRN Line</label>
            <p-multiSelect
              [options]="po_grn_line_list"
              placeholder="Number-line-description"
              filter="false"
              inputStyleClass="form-control"
              optionLabel="GRNField"
              [maxSelectedLabels]="3"
              [selectedItemsLabel]="'{0} items selected'"
              name="PO_GRN_Number_line"
              ngModel
              [(ngModel)]="PO_GRN_Number_line"
              [disabled]="!grnLine"
              [pTooltip]="!grnLine ? 'Select GRN Line' : null"
              tooltipPosition="top"
            >
            </p-multiSelect>
          </div>

          <!-- <div
            *ngIf="
              viewType != 'ideal' &&
              selectedInvoiceType_quick == 'invoice' &&
              selectedCategory != 'returns'
            "
            class="selectFilter w-value"
          >
            <button
              class="secondary_btn f-12"
              type="button"
              [disabled]="!flipBool"
              [ngClass]="{
                disable: !flipBool,
                'b-success': isPOFlipped
              }"
              (click)="open_dialog_comp('flip')"
            >
              <span class="mr-3">Flip PO</span>
              <span
                *ngIf="isPOFlipped"
                class="material-icons d-flex justify-content-center"
              >
                check_circle
              </span>
            </button>
          </div> -->

          <div *ngIf="approvalBoolean" class="selectFilter" style="width: 200px;">
            <label class="check_div">
              <input
                type="checkbox"
                name="preApprove"
                [(ngModel)]="preAproveBool"
                ngModel
                (change)="onSelectPreaprve(preAproveBool)"
              />
              <span class="f-12">Pre Approved</span>
            </label>
          </div>
          <div *ngIf="approvalBoolean" class="selectFilter w-value">
            <button
              class="secondary_btn f-12"
              type="button"
              [disabled]="preAproveBool"
              [ngClass]="{
                disable: preAproveBool,
                'b-success': approverNameListFinal?.length > 0
              }"
              (click)="approverDialog = true"
            >
              <span *ngIf="!(approverNameListFinal?.length > 0)"
                >+  Add Approvers</span
              >
              <span
                *ngIf="approverNameListFinal?.length > 0"
                class="material-icons d-flex justify-content-center"
              >
                check_circle
              </span>
            </button>
          </div>

          <div class="attachments">
            <div class="selectFilter">
              <div class="f-12 mb-1">Invoice</div>
              <label
                for="img1"
                class="f-14 d-flex justify-content-between"
              >
                <div>Attach Invoice</div>
                <span
                  class="material-icons d-block"
                  [ngClass]="invoiceFilename != ''? 'color_green':''"
                >
                  {{invoiceFilename != ''? 'check_circle' :'upload_file'}}
                </span>
              </label>
              <input
                accept=".png, .jpg, .pdf, .html, .htm"
                (change)="onSelectFile_quick($event, 'invoice')"
                type="file"
                id="img1"
                hidden
                name="attchedInvoice"
                ngModel
                required
              />
            </div>

            <div class="selectFilter">
              <div class="f-12 mb-1">Support Documents</div>
              <label
                for="img11"
                class="f-14 d-flex justify-content-between"
              >
                <div>Attach Support</div>
                <span
                  class="material-icons d-block"
                  [ngClass]="supportFileNamelist?.length > 0 ? 'color_green':''"
                >
                {{supportFileNamelist?.length > 0? 'check_circle' :'upload_file'}}
                </span>
              </label>
              <input
                accept=".doc, .pdf"
                (change)="onSelectFile_quick($event, 'support')"
                type="file"
                id="img11"
                hidden
                name="attchedSupport"
                multiple
                ngModel
              />
            </div>
          </div>

          <div class="footer">
              <button
                class="secondary_btn f-12"
                [disabled]="quickUploadForm.invalid"
                [ngClass]=" quickUploadForm.invalid ? 'disable' : '' "
              >
                 Add Details
              </button>
              <button
                class="primary_btn f-12"
                type="button"
                (click)="uploadInvoicesListData?.length > 1 ? uploadAllFiles(): uploadSingle(quickUploadForm.invalid,quickUploadForm.value)"
              >
                Upload
              </button>
          </div>
        </form>
      </div>

      <div
        class="tab-pane"
        id="help"
        role="tabpanel"
        aria-labelledby="help-tab"
        [ngClass]="viewType == 'help' ? 'show active' : 'fade'"
      >
        <help></help>
      </div>
    </div>
  </div>
</div>

<!-- </div>
</div> -->
<!--hiding pdf not to view-->
<div #hiddenContainer style="position: absolute; left: -9999px; top: -9999px">
  <div *ngIf="attachedBoolean">
    <pdf-viewer
      [src]="pdfSrc"
      [show-all]="true"
      [original-size]="false"
      [fit-to-page]="true"
      [render-text]="true"
      [render-text-mode]="2"
      (after-load-complete)="afterLoadComplete($event)"
    >
    </pdf-viewer>
  </div>
</div>

<!-- Selecting MultiPO modal-->
<p-dialog
  header="Multiple PO"
  [(visible)]="mutliPODailog"
  position="right"
  [modal]="true"
  [style]="{ width: '55vw', height: '100vh', margin:'0' }"
  [baseZIndex]="10"
  [draggable]="false"
  [resizable]="false"
>
  <div class="multipo_pop">
      <div class="f-14">Please select date from to to</div>
      <div class="d-flex">
        <div class="selectFilter">
          <label class="f-12 mb-0">Select Dates for PO</label><br />
          <p-calendar
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            showButtonBar="true"
            (onClearClick)="clearDates()"
            placeholder="Select dates to Filter"
            [readonlyInput]="true"
            [showIcon]="true"
            inputId="range"
          ></p-calendar>
  
        </div>
        <button class="primary_btn f-12 ml-2 mt-2" (click)="filterData(rangeDates)">
          <!-- <i class="fa fa-filter" aria-hidden="true"></i> -->
          Filter
        </button>
      </div>

      <div class="multi_upload d-flex align-items-center">
        <div class="f-12 pointer" (click)="downloadTemplate()">
          Download Template
          <i class="fa fa-download mr-2 ml-2"></i>
        </div>
        <div style="color: lightgrey;">|</div>
        <div style="margin-top: 7px" class="ml-2">
          <input
            type="file"
            id="custom-upload"
            [(ngModel)]="uploadExcelValue"
            (change)="onChange($event)"
            hidden
            #inputFile
            accept=".xls,.xlsx"
          />
          <label for="custom-upload" class=" f-12 mr-2">
            <span>Upload Excel</span>
            <i class="fa fa-upload mr-2 ml-2"></i>
          </label
          >
        </div>
      </div>
    <form
      class="d-flex flex-wrap multiPO mt-2"
      style="gap: 10px"
      #multiPO="ngForm"
      (ngSubmit)="addMultiPOLines(multiPO.value)"
    >
      <div class="selectFilter">
        <label class="f-12 mb-0">Select PO Number</label><br />
        <p-autoComplete
          placeholder="PO Number"
          (onSelect)="selectedPO($event)"
          [group]="false"
          [suggestions]="filteredPO"
          (completeMethod)="filterPOnumber($event)"
          field="PODocumentID"
          optionDisabled="idDocument"
          [dropdown]="true"
          showEmptyMessage="true"
          name="PODocumentID"
          ngModel
          required
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.PODocumentID }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">Select PO Line</label><br />
        <p-autoComplete
          placeholder="PO Description"
          (onSelect)="selectedPOLine($event)"
          [group]="false"
          [suggestions]="filteredPOLines"
          (completeMethod)="filterPOLine($event)"
          field="Name"
          [dropdown]="true"
          name="Name"
          ngModel
          required
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.Name }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">Select GRN Number</label><br />
        <p-autoComplete
          placeholder="GRN Number"
          (onSelect)="selectedGRN($event, 'grn_num')"
          [group]="false"
          [suggestions]="filteredGRN"
          (completeMethod)="filterGRNnumber($event, 'grn_num')"
          field="PackingSlip"
          [dropdown]="true"
          showEmptyMessage="true"
          name="GRN_number"
          ngModel
          required
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.PackingSlip }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">Select GRN Line</label><br />
        <p-autoComplete
          placeholder="GRN Description"
          (onSelect)="selectedGRN($event, 'grn_line')"
          [group]="false"
          [suggestions]="filteredGRN"
          (completeMethod)="filterGRNnumber($event, 'grn_line')"
          field="Name"
          [dropdown]="true"
          name="GRN_Name"
          ngModel
          required
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.Name }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <div class="selectFilter">
        <label class="f-12 mb-0">PO Unit price</label><br />
        <input
          type="text"
          class="form-control f-14 accountSelect width-100"
          name="POLineAmount"
          [(ngModel)]="PO_amount_line"
          required
          readonly
        />
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">PO Qty</label><br />
        <input
          type="text"
          class="form-control f-14 accountSelect width-100"
          name="ConsumedPOQty"
          [(ngModel)]="PO_qty"
          required
        />
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">GRN Line Amount</label><br />
        <input
          type="text"
          class="form-control f-14 accountSelect width-100"
          name="GRNLineAmount"
          [(ngModel)]="GRN_amount_line"
          required
          readonly
        />
      </div>

      <div class="selectFilter">
        <label class="f-12 mb-0">GRN Qty</label><br />
        <input
          type="text"
          class="form-control accountSelect  f-14 width-100"
          name="GRNQty"
          [(ngModel)]="GRN_qty"
          required
        />
      </div>

      <div class="btn_fix">
        <button
          class="secondary_btn f-12 mr-2"
          [disabled]="multiPO.invalid"
          [ngStyle]="{ cursor: multiPO.invalid ? 'not-allowed' : 'pointer' }"
        >
          + Add
        </button>
      </div>
    </form>

    <div class="mt-1">
      <div class="f-14">Recently Added Details</div>
      <div *ngFor="let obj of mutliplePOTableData" class="addDetails">
        <div *ngFor="let item of obj|keyvalue">
          <label class="f-12 shade_color mb-0">{{item.key}}</label>
          <div class="f-12" [ngStyle]="{'width':item?.key?.includes('Name')? '160px':'110px'}">{{item.value}}</div>
        </div>
      </div>
    </div>

  </div>

  <ng-template pTemplate="footer">
    <div class="btnAction fixed_pos">

      <button
        type="button"
        class="primary_btn f-12"
        [disabled]="mutliplePOTableData?.length < 1"
        [ngStyle]="{ cursor: mutliplePOTableData?.length < 1 ? 'not-allowed' : 'pointer' }"
        (click)="submitMultiPO()"
      >
        {{ multiBtn }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  header="Add Approvers"
  [(visible)]="approverDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '450px' }"
  [baseZIndex]="10"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
>
  <div
    *ngIf="approverNameList?.length != 0; else noapprovers"
    class="d-flex flex-wrap justify-content-center pt-2"
    style="gap: 5px"
  >
    <div class="selectFilter" *ngFor="let item of approverList | keyvalue; let i = index">
      <label class="f-12 mb-0">Select Approver {{ i + 1 }}</label>
      <!-- <div>{{item.value | json}}</div> -->
      <select
        class="form-control accountSelect f-14"
        [disabled]="preApproveBoolean"
        (change)="onSelectApprovers($event.target.value, i)"
      >
        <option
          *ngFor="let element of item.value"
          [value]="element.User.idUser"
        >
          {{ element.User.firstName }} {{ element.User.lastName }}
        </option>
      </select>
    </div>
  </div>
  <ng-template #noapprovers>
    <div class="p-t-30 f-12 text-center">
      Approvers are not available please select the pre-approved option.
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="primary_btn f-12"
        (click)="addApprovers()"
      >
        Add
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
<ngx-spinner
  bdColor="rgba(251, 251, 251, 0.8)"
  size="medium"
  color="#070900"
  [fullScreen]="false"
  type="ball-spin-clockwise"
>
  <div class="loader_text">
    {{ spinMsg }}
  </div>
</ngx-spinner>
