<app-business-charts></app-business-charts>
<!-- <div class="mainDiv">
    <div class="left">
        <div class="options">
            <div *ngFor="let item of menu_list" [routerLink]="item.route_name" class="option f-13" [ngClass]="item.route_name == active_tab ? 'active_cls':''" (click)="tabChange(item.route_name)">{{item.name}}</div>

        </div>
    </div>
    <div class="right">
        <div *ngIf="active_tab == 'overview'">
            <app-process-reports></app-process-reports>
        </div>
        <div *ngIf="active_tab == 'exception_analysis'">
            <app-exception-reports></app-exception-reports>
        </div>
    </div>
</div> -->