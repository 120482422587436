
<div class="row1">
  <!-- <div class="col-md-2 p-0"></div> -->
  <div class="col-md-12 p-0">
    <div
      class="nav_pane_width1 d-flex justify-content-between flex-wrap inv_nav_container pt-2"
    >
      <ul class="nav nav-tabs d-flex  nav_sub" [class.reversed]="!vendorInvoiceAccess">
        <li class="nav-item" *ngIf="vendorInvoiceAccess">
          <a
            [ngClass]="route.url == invoiceTab ? 'active' : 'shade_color'"
            class="nav-link "
            (click)="menuChange('invoice')"
            >Invoice({{ allInvoiceLength }})
            <i
              class="fa fa-refresh cursor"
              [ngClass]="refreshBool ? 'rotate' : ''"
              (click)="refreshInvoice('inv')"
            ></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="vendorInvoiceAccess">
          <a
            [ngClass]="route.url == POTab ? 'active' : 'shade_color'"
            class="nav-link "
            (click)="menuChange('po')"
            >PO({{ poArrayLength }})</a
          >
        </li>
        <li class="nav-item" *ngIf="vendorInvoiceAccess">
          <a
            [ngClass]="route.url == GRNTab ? 'active' : 'shade_color'"
            class="nav-link "
            (click)="menuChange('grn')"
            >GRN({{ GRNArrayLength }})</a
          >
        </li>
        <li class="nav-item">
          <a
            [ngClass]="route.url == archivedTab ? 'active' : 'shade_color'"
            class="nav-link "
            (click)="menuChange('archived')"
            >Posted({{ archivedLength }})</a
          >
        </li>
        <li class="nav-item" *ngIf="!isMobile">
          <a
            [ngClass]="route.url == rejectedTab ? 'active' : 'shade_color'"
            class="nav-link "
            (click)="menuChange('rejected')"
            >Rejected({{ rejectedLength }})</a
          >
        </li>

        <li class="nav-item service" *ngIf="usertypeBoolean  && serviceInvoiceAccess"
        >
          <a
            [ngClass]="route.url == serviceInvoiceTab ? 'active' : 'shade_color'"
            class="nav-link ite "
            (click)="menuChange('ServiceInvoices')"
            >Service <span *ngIf="!isMobile">Invoices</span>({{ serviceInvoiceLength }})
            <i
              class="fa fa-refresh cursor"
              [ngClass]="resfrshBool ? 'rotate' : ''"
              (click)="refreshInvoice('ser')"
            ></i
          ></a>
        </li>
      </ul>



      <div class="d-flex filters">
        <div class="filterDiv posted_filter" *ngIf="route.url == archivedTab">
          <div class="filter_box">
            <label class="f-12 z_index">Select</label>
            <select
              class="f-14"
              (change)="selectinvType($event.target.value)"
            >
              <option value=" ">Both</option>
              <option value="ven">Vendor</option>
              <option value="ser">Service</option>
            </select>
          </div>
        </div>
        <div class="filterDiv" *ngIf="route.url == invoiceTab || route.url == serviceInvoiceTab">
          <div class="filter_box">
            <label for="uni" class="f-12">
              Search Universal </label>
              <input
                [(ngModel)]="searchText"
                type="text"
                id="uni"
                class="textFilter f-14"
                [placeholder]="search_placeholder"
                (keyup)="universalSearch(searchText)"
              />
          </div>
          <p-calendar
            #datePicker
            [(ngModel)]="rangeDatesinv"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder=""
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            (onClearClick)="clearDates()"
            (onSelect)="filterByDate(rangeDatesinv)"
            inputId="range"
          ></p-calendar>
        </div>
        <div
          class="filterDiv "
          *ngIf="
            !(
              route.url.includes('allInvoices') ||
              route.url.includes('GRNExceptions') ||
              route.url.includes('ServiceInvoices')
            )
          "
        >
          <div class="filter_box">
            <label class="f-12 z_index">Search</label>
            <input
              #serachStr
              type="text"
              class="textFilter f-14"
              placeholder="Ex: PO number, etc.."
              (keyup)="keySearch(serachStr.value,$event)"
              [(ngModel)]="searchStr"
            />
          </div>
          <button
            class="secondary_btn b-r-left-0 click_search"
            (click)="filterString(serachStr.value)"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
        <div class="filter_box export" style="max-width: 100px;" *ngIf="(route.url.includes('allInvoices') || route.url.includes('archived') || route.url.includes('rejected')) && isAdmin">
          <button class="primary_btn f-12" (click)="openSOADialog('soa')"> SOA </button>
        </div>
        <div class="filter_box export" style="max-width: 100px;" *ngIf="route.url.includes('ServiceInvoices') && ERPName == 'Dynamics AX' ">
          <button class="primary_btn f-12" (click)="openSOADialog('ERP')"> Report </button>
        </div>
        <div *ngIf="!isMobile" class="filter_box status export">
          <button class="secondary_btn f-18" (click)="exportExcel()">
            <i class="fa fa-download"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="pt-2 tabDiv">
      <app-exception-table
        [invoiceColumns]="invoiceColumns"
        [columnsToDisplay]="columnstodisplayInvoice"
        [showPaginatorAllInvoice]="showPaginatorAllInvoice"
        [ColumnLength]="allInColumnLength"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        [columnsData]="invoiceDisplayData"
        [searchText]="searchText"
        (filterDataEmit)="filterEmit($event)"
        (systemCheckEmit)="refreshInvoice($event)"
        (paginationEvent) = "paginate($event)"
        [pageNumber]="pageNumber"
        [pageId]="'inv'"
        (sideBarBoolean)="showSidebar($event)"
        *ngIf="route.url == invoiceTab"
      >
      </app-exception-table>
        <app-exception-table
        [invoiceColumns]="poColumns"
        [columnsToDisplay]="columnstodisplayPO"
        [showPaginatorAllInvoice]="showPaginatorPOTable"
        [ColumnLength]="allPOColumnLength"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        [columnsData]="poDispalyData"
        [searchText]="searchText"
        (paginationEvent) = "paginate($event)"
        (systemCheckEmit)="refreshInvoice($event)"
        [pageId]="'PO'"
        [pageNumber]="pageNumber"
        (sideBarBoolean)="showSidebar($event)"
        *ngIf="route.url == POTab"
      >
      </app-exception-table>


      <app-exception-table
        [invoiceColumns]="GRNColumns"
        [columnsToDisplay]="columnstodisplayGRN"
        [showPaginatorAllInvoice]="showPaginatorGRNTable"
        [ColumnLength]="GRNColumnLength"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        [columnsData]="GRNDispalyData"
        [searchText]="searchText"
        (paginationEvent) = "paginate($event)"
        (systemCheckEmit)="refreshInvoice($event)"
        [pageId]="'GRN'"
        [pageNumber]="pageNumber"
        (sideBarBoolean)="showSidebar($event)"
        *ngIf="route.url == GRNTab"
      >
      </app-exception-table>

      <app-exception-table
      [invoiceColumns]="archivedColumns"
      [columnsToDisplay]="columnstodisplayArchived"
      [showPaginatorAllInvoice]="showPaginatorArchived"
      [ColumnLength]="allARCColumnLength"
      (searchInvoiceData)="searchInvoiceDataV($event)"
      [columnsData]="archivedDisplayData"
      [searchText]="searchText"
      (paginationEvent) = "paginate($event)"
      [pageId]="'arc'"
      [pageNumber]="pageNumber"
      (sideBarBoolean)="showSidebar($event)"
      *ngIf="route.url == archivedTab"
    >
    </app-exception-table>

      <!-- <app-all-invoices
        [tableData]="archivedDisplayData"
        [invoiceColumns]="archivedColumns"
        [columnsToDisplay]="columnstodisplayArchived"
        [showPaginatorAllInvoice]="showPaginatorArchived"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        (sideBarBoolean)="showSidebar($event)"
        (paginationEvent)="paginate($event)"
        (filterDataEmit)="filterEmit($event)"
        [columnLength]="allARCColumnLength"
        *ngIf="route.url == archivedTab"
      ></app-all-invoices> -->

      <app-exception-table
      [invoiceColumns]="rejectedColumns"
      [columnsToDisplay]="columnstodisplayrejected"
      [showPaginatorAllInvoice]="showPaginatorRejected"
      [ColumnLength]="rejectedColumnLength"
      (searchInvoiceData)="searchInvoiceDataV($event)"
      [columnsData]="rejectedDisplayData"
      [searchText]="searchText"
      (paginationEvent) = "paginate($event)"
      (filterDataEmit)="filterEmit($event)"
      [pageId]="'reject'"
      [pageNumber]="pageNumber"
      (sideBarBoolean)="showSidebar($event)"
      *ngIf="route.url == rejectedTab"
    >
    </app-exception-table>

      <!-- <app-all-invoices
        [tableData]="rejectedDisplayData"
        [invoiceColumns]="rejectedColumns"
        [columnsToDisplay]="columnstodisplayrejected"
        [showPaginatorAllInvoice]="showPaginatorRejected"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        (sideBarBoolean)="showSidebar($event)"
        (paginationEvent)="paginate($event)"
        (filterDataEmit)="filterEmit($event)"
        [columnLength]="rejectedColumnLength"
        *ngIf="route.url == rejectedTab"
      ></app-all-invoices> -->

      <app-exception-table
      [invoiceColumns]="serviceColumns"
      [columnsToDisplay]="columnstodisplayService"
      [showPaginatorAllInvoice]="showPaginatorServiceInvoice"
      [ColumnLength]="allSRVColumnLength"
      (searchInvoiceData)="searchInvoiceDataV($event)"
      [columnsData]="serviceinvoiceDispalyData"
      [searchText]="searchText"
      (systemCheckEmit)="refreshInvoice($event)"
      (paginationEvent) = "paginate($event)"
      [pageId]="'service'"
      (sideBarBoolean)="showSidebar($event)"
      (filterDataEmit)="filterEmit($event)"
      [pageNumber]="pageNumber"
      *ngIf="route.url == serviceInvoiceTab"
    >
    </app-exception-table>

      <!-- <app-all-invoices
        [tableData]="serviceinvoiceDispalyData"
        [invoiceColumns]="serviceColumns"
        [showPaginatorAllInvoice]="showPaginatorServiceInvoice"
        [columnsToDisplay]="columnstodisplayService"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        (sideBarBoolean)="showSidebar($event)"
        (paginationEvent)="paginate($event)"
        (filterDataEmit)="filterEmit($event)"
        [columnLength]="allSRVColumnLength"
        *ngIf="route.url == serviceInvoiceTab"
      ></app-all-invoices> -->
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(251, 251, 251, 0.8)"
    size="medium"
    color="#070900"
    [fullScreen]="false"
    type="ball-spin-clockwise"
  >
    <div class="loader_text">
      Hey, please wait...
      <div *ngIf="showFactsComponent">
        <fun-facts [factsList]="factsList"></fun-facts>
      </div>
    </div>
  </ngx-spinner>


  <dialog #dialog  [ngClass]="dialogHeader == 'SOA Reports'? 'soaDialog':'grnDialog'">
    <div class="d-flex justify-content-between mb-2">
      <h6 class="f-14">{{dialogHeader}}</h6>
      <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
    </div>

    <!-- GRN report download -->
    <div *ngIf="dialogHeader == 'GRN Reports'">
      <div class="inputBox">
        <label class="f-12"> Start & End Date </label> <br>
        <p-calendar
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder="Select dates to Filter"
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            (onClearClick)="clearDates()"
            inputId="range"
          ></p-calendar>
      </div>
      <div class="inputBox" >
        <label class="f-12">Created by</label><br />
        <p-autoComplete
          placeholder="Select created by user"
          (onSelect)="selectedUser($event)"
          [group]="false"
          [suggestions]="userList"
          (completeMethod)="filterUser($event)"
          field="firstName"
          [dropdown]="true"
          name="created"
        >
        </p-autoComplete>
      </div>
      <div class="inputBox">
        <label for="email" class="f-12">Email ID</label> <br>
        <input type="email" id="email" class="form-control f-14" [(ngModel)]="userEmailID">
      </div>
      <div class="d-flex justify-content-between pointer">
        <div class="inputBox f-14 mr-3 selectionDiv" (click)="grnDownloadSelection('All')" [ngClass]="grnTabDownloadOpt == 'All'?'activeClass':''">
          All Records
        </div>
        <div class="inputBox f-14 selectionDiv pointer" (click)="grnDownloadSelection('Pending')" [ngClass]="grnTabDownloadOpt == 'Pending'?'activeClass':''">
          Pending Invoice
        </div>
      </div>
    </div>

    <!-- SOA Reports -->
    <div *ngIf="dialogHeader == 'SOA Reports' || dialogHeader == 'ERP Reports'" >
      <div class="d-flex soaDiv" [ngClass]="dialogHeader == 'ERP Reports'?'erpDiv':''">
        <div class="inputBox soaBox">
          <label class="f-12"> Start & End Date </label> <br>
          <p-calendar
              [(ngModel)]="rangeDates_soa"
              selectionMode="range"
              [minDate]="minDate"
              [maxDate]="maxDate"
              placeholder="Select dates to Filter"
              [readonlyInput]="true"
              [showIcon]="true"
              showButtonBar="true"
              (onClearClick)="clearDates()"
              inputId="range"
            ></p-calendar>
        </div>

        <div class="soaBox">
          <label class="f-12">Entity name</label><br />
          <p-autoComplete
            placeholder="Select Entity"
            (onSelect)="selectEntity($event)"
            [group]="false"
            [suggestions]="filteredEnt"
            (completeMethod)="filterEntity($event)"
            field="EntityName"
            [dropdown]="true"
            [completeOnFocus]="true"
            [autofocus]="true"
            name="EntityName"
            [(ngModel)]="selectedEntityId"
          >
          </p-autoComplete>
        </div>

        <!--Vendor Selection-->
        <div class="soaBox" *ngIf='portal_name == "customer" && dialogHeader == "SOA Reports"'>
          <label class="f-12">VendorName</label><br />
          <p-autoComplete
            placeholder="Select vendor"
            (onSelect)="selectedVendor($event)"
            [group]="false"
            [suggestions]="filteredVendors"
            (completeMethod)="filterVendor($event)"
            field="VendorName"
            [dropdown]="true"
            [completeOnFocus]="true"
            [autofocus]="true"
            name="vendor"
            [(ngModel)]="selectedVendorId"
          >
          </p-autoComplete>
        </div>

        <!--Service Selection-->
        <div class="soaBox" *ngIf='dialogHeader == "ERP Reports"'>
          <label class="f-12">ServiceProvider</label><br />
          <p-autoComplete
            placeholder="Select service"
            (onSelect)="selectedVendor($event)"
            [group]="false"
            [suggestions]="filteredService"
            (completeMethod)="filterService($event)"
            field="ServiceProviderName"
            [dropdown]="true"
            [completeOnFocus]="true"
            [autofocus]="true"
            name="ServiceProvider"
            [(ngModel)]="selectedServiceId"
          >
          </p-autoComplete>
        </div>

        <div class="inputBox soaBox" *ngIf='dialogHeader == "SOA Reports"'>
          <label class="f-12">PO number/Invoice number</label><br />
          <input type="text" class="form-control f-14" [(ngModel)]="soa_uniSearch">
        </div>

        <!-- <div class="inputBox soaBox">
          <label class="f-12">Invoice number</label><br />
          <input type="text" class="form-control f-14">
        </div> -->

        <div *ngIf="dialogHeader == 'SOA Reports'">
          <!-- <label class="f-12 mb-0" for="hide" style="visibility: hidden;">Hide</label><br> -->
          <button class="primary_btn f-12" (click)="soaSearch(false)"> Search </button>
        </div>

        <div *ngIf="dialogHeader == 'SOA Reports'">

          <!-- <label class="f-12 mb-0" for="hiden" style="visibility: hidden;">Hide</label><br> -->
          <button class="primary_btn f-12" (click)="soaSearch(true)"> Download report to email </button>
        </div>
        <div *ngIf="dialogHeader == 'ERP Reports'" class="d-flex justify-content-end">
          <button class="primary_btn f-12 mr-3" (click)="ERPReports(false)"> Download </button>
          <button class="primary_btn f-12" (click)="ERPReports(true)"> Download to email </button>
        </div>
      </div>

      <div class="soaTable" *ngIf="dialogHeader == 'SOA Reports'">
        <app-exception-table
          [columnsData]="SOATableData"
          [invoiceColumns]="columnsForSOA"
          [ColumnLength]="columnLengthSOA"
        >
        </app-exception-table>
        <!-- <table class="d-flex">
          <tr  *ngFor="let data of SOATableData | keyvalue" style="display: block;">
            <th [ngStyle]="{width: data.key == 'Description'?'200px':'auto'}" >{{data.key}}</th>
            <td *ngFor="let data1 of data.value">{{data1}}</td>
          </tr>
        </table> -->
      </div>

    </div>

    <div *ngIf="dialogHeader == 'GRN Reports'"  class="footer d-flex justify-content-end" >
      <button class="primary_btn f-12" (click)='email_download()' [disabled]="!userEmailID">Download to email</button>
    </div>
    <ngx-spinner
        bdColor="rgba(251, 251, 251, 0.8)"
        size="medium"
        color="#070900"
        [fullScreen]="false"
        type="ball-spin-clockwise"
      >
        <div class="loader_text">
          Hey, please wait...
        </div>
      </ngx-spinner>
  </dialog>
</div>

<p-dialog header="Select Columns" [(visible)]="visibleSidebar2" position="right" [modal]="true" [style]="{width: '300px',height:'100vh',margin:'0px'}" [baseZIndex]="10000"
[draggable]="false" [resizable]="false">
  <ul
    class="pl-3 sidebar_height"
    cdkDropList
    (cdkDropListDropped)="onOptionDrop($event)"
  >
    <li
      class="allCols"
      *ngFor="let displayColumn of allColumns; let i = index"
      cdkDrag
    >
      <label class="container" [for]="i" cdkDragHandle
        >{{ displayColumn.columnName }}
        <input
          type="checkbox"
          [id]="i"
          (change)="activeColumn($event, displayColumn)"
          [checked]="displayColumn.isActive == 1"
        />
        <span class="checkmark"></span>
      </label>
      <i class="fa fa-bars dragCols" aria-hidden="true" cdkDragHandle></i>
    </li>
  </ul>

  <div class="btnFooterSave" mat-dialog-actions>
    <button
      class="secondary_btn f-12 mr-2"
      type="button"
      (click)="visibleSidebar2 = false"
    >
      Cancel
    </button>
    <button
      class="primary_btn f-12"
      (click)="updateColumnPosition()"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</p-dialog>

<p-toast></p-toast>
