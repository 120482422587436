<div *ngIf="grnTabDatalength>0">
    <mat-accordion>
      <div class="row m-0"  *ngFor="let grnitem of GRNTabData | keyvalue; let i = index;">
        <div class="col-sm-12">
          <mat-expansion-panel 
          [expanded]="true"
            (opened)="setOpened(i)"
            (closed)="setClosed(i)" style="margin-top: 10px;">
            
            <mat-expansion-panel-header class="KPIName">
              <mat-panel-title class=" secondheadertoggle">
                
                <h6 class="kpi-header f-13 faPulsandMinus head-pos">
                  {{ grnitem?.key }}
                </h6>
              
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>

            <table class="w-100 mt-2 ex_table">
              <thead>
                <tr>
                  <th class="f-12 p-2 text-center" style="min-width: 50px; background-color: #ebebeb;">S.No</th>
                  <th class="f-12 p-2 text-center" style="min-width: 50px; background-color: #ebebeb;" *ngFor="let header of lineTableHeaders" class="lineName">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of grnitem.value; let i = index">
                  <tr>
                    <ng-container  *ngIf="item">
                      <td class="linevalue p-2 text-center" style="width: 50px;">{{ i + 1 }}</td>
                      <td *ngFor="let header of lineTableHeaders" >
                        <div class="d-flex">
                          <input
                              #inputv
                              type="text"
                              [ngClass]="
                              header.includes('Description','Name','POLineDescription','GRNLineDescription')
                                  ? 'w-des'
                                  : 'w-sm'
                              "
                              [value]="item[header]?.Value"
                              [title]="item[header]?.Value"
                              [name]="header"
                              disabled
                              class="form-control f-12 input_disabled"
                              />
                        </div>
                      </td>

                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>

            </table>
            </ng-container>
      
          </mat-expansion-panel>
        </div>
      </div>
      
      
    </mat-accordion>
  </div>
  <div *ngIf="!(grnTabDatalength>0)" class="d-flex justify-content-center f-13 mt-4">
    No GRN is mapped with the Invoice.
  </div>
