<!-- <mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav sideBar d-flex" [mode]="sidebarMode"
  [opened]="isDesktop">
    <div class="d_desk1">
      <div class="logotitle">
        <div class="logoPlaceholder">
          <img
            class="logo_img"
            src="assets/serinaLogo01.png"
            alt="logo"
            width="130"
            height="42"
          />
        </div>
        <div class="placeLogo">
          <span class="portalName">Vendor Portal</span>
        </div>
      </div>
      <div class="routeLinks">
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Dashboard"
          [routerLink]="'home'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            home
          </span>
          Dashboard
        </div>

        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Upload"
          [routerLink]="'uploadInvoices'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            upload_file
          </span>
          Upload
        </div>

        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Document Status"
          [routerLink]="'invoice'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            receipt
          </span>
          Document Status
        </div>

        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Exceptions"
          [routerLink]="'ExceptionManagement'"
        >
          <i
            class="fa fa-pencil-square f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Exceptions
        </div>

        <div
          *ngIf="addUsersBoolean"
          class="navLink"
          data-text="Contact Details"
          routerLinkActive="active"
          [routerLink]="'vendorContacts'"
        >
          <i
            class="fa fa-address-book f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Contact Details
        </div>
        <div *ngIf="!isDesktop" class="navLink" data-text="Change Password" (click)="openDialog()">
          <i
            class="fa fa-lock f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Change Password
        </div>

        <div
          *ngIf="!isDesktop"
          class="navLink"
          data-text="Logout"
          (click)="logout_confirmation()"
        >
          <i
            class="fa fa-sign-out f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Logout
        </div>
      </div>
      <div class="copy">
        Copyrights <span class="cpr f-14">&#169;</span> 2024
      </div>
    </div>
  </mat-drawer>
  <div class="example-sidenav-content">
    <div class="bodyContent">
      <div class="d_desk1">
        <i
          class="fa fa-bars menu_icon f-16"
          aria-hidden="true"
          (click)="drawer.toggle()"
        ></i>
        <div class="f-right d_desk">
          <span class="userName last_log f-11">
            <span>Last login :&nbsp; </span>
            {{ last_login | date : "medium" }}
            <span style="color: crimson">{{ timezone }}</span> &nbsp; &nbsp;
          </span>
          <span
            class="b-right"
            style="padding-right: 14px; cursor: pointer"
            [routerLink]="'notifications'"
          >
            <i
              class="pi pi-bell p-mr-4 p-text-secondary f-18"
              [value]="numberOfNotify"
              pBadge
            ></i
          ></span>

          <span class="ml-2 dropdown">
            <i class="fa fa-user-circle-o profileIcon" aria-hidden="true"></i>
          </span>
          <span class="b-left-sky clr userName"
            >{{ userDetails?.userdetails?.firstName }}
          </span>
          <span (clickOutside)="onClickedOutside($event)">
            <mat-icon
              class="sign_out dropdown"
              svgIcon="logout"
              title="Sign Out"
              (click)="isActive()"
            ></mat-icon>
            <div
              [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
              class="dropdown-content"
            >
              <a (click)="openDialog()">Change Password</a>
              <a (click)="logout_confirmation()">Logout</a>
            </div>
          </span>
        </div>
      </div>

      <nav class="d_mobile1">
        <section class="pos_rel" *ngIf="menubarBoolean">
          <div class="routeLinks route_mobile">
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Dashboard"
              [routerLink]="'home'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                home
              </span>
              Dashboard
            </div>

            <div
              class="navLink"
              routerLinkActive="active_cls"
              data-text="Upload"
              [routerLink]="'uploadInvoices'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                upload_file
              </span>
              Upload
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Document Status"
              [routerLink]="'invoice'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                receipt
              </span>
              Document Status
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Action Center"
              [routerLink]="'action-center'"
            >
              <i
                class="fa fa-universal-access f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Action Center
            </div>

            <div
              *ngIf="addUsersBoolean"
              class="navLink"
              data-text="Contact Details"
              routerLinkActive="active"
              [routerLink]="'vendorContacts'"
            >
              <i
                class="fa fa-address-book f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Contact Details
            </div>

            <div
              class="navLink"
              data-text="Change Password"
              (click)="openDialog()"
            >
              <i
                class="fa fa-lock f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Change Password
            </div>

            <div
              class="navLink"
              data-text="Logout"
              (click)="logout_confirmation()"
            >
              <i
                class="fa fa-sign-out f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Logout
            </div>
          </div>
        </section>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container> -->
<div class="updates">
  🚀 We've just rolled out exciting new updates to enhance your experience. <span class="pointer" (click)="openUpdatesWindow()">Click here </span> to explore what's new!
</div>
<div class="layout">
  <nav class="desktop d-flex justify-content-between"
  [ngClass]="
      DS.configData.client_name == 'Cenomi' ? 'cenomiBg' : ''
    ">
    <div class="d-flex" style="gap: 60px">
      <div>
        <img
          [src]="logoSrc"
          alt="Serina logo"
          [ngClass]="
          ['Cenomi','AGI'].includes(DS?.configData?.client_name) ? 'otherBrand' : 'Serina'
          "
        />
      </div>
      <div
        class="navLinks d-flex justify-content-between"
        *ngIf="!isNewVendorForERP && isDesktop"
      >
        <div
          class="nav_Link f-14"
          [ngClass]="
            DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''
          "
          routerLinkActive="active_cls"
          [routerLink]="'home'"
        >
          Dashboard
        </div>

        <div
          class="nav_Link f-14"
          [ngClass]="
            DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''
          "
          routerLinkActive="active_cls"
          [routerLink]="'uploadInvoices'"
        >
          Upload
        </div>

        <div
          class="nav_Link f-14"
          [ngClass]="
            DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''
          "
          routerLinkActive="active_cls"
          [routerLink]="'invoice'"
        >
          Document Status
        </div>

        <div
          class="nav_Link f-14"
          [ngClass]="
            DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''
          "
          routerLinkActive="active_cls"
          [routerLink]="'ExceptionManagement'"
        >
          Exceptions
        </div>

        <!-- <div
            class="navLink"
            routerLinkActive="active_cls"
            data-text="Payment Status"
            [routerLink]="'payment-details-vendor'"
          >
            Payment Status
          </div> -->

        <!-- <div *ngIf="addUsersBoolean" class="navLink" data-text="Roles" routerLinkActive="active_cls" [routerLink]="'vendorUsers'">
                      <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                          manage_accounts
                          </span>
                          Roles
                  </div> -->

        <div
          *ngIf="addUsersBoolean"
          class="nav_Link f-14"
          [ngClass]="
            DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''
          "
          routerLinkActive="active_cls"
          [routerLink]="'vendorContacts'"
        >
          Contact Details
        </div>
        <div
          *ngIf="!isDesktop"
          class="navLink"
          data-text="Change Password"
          (click)="openDialog()"
        >
          <i
            class="fa fa-lock f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Change Password
        </div>

        <div
          *ngIf="!isDesktop"
          class="navLink"
          data-text="Logout"
          (click)="confirm_pop()"
        >
          <i
            class="fa fa-sign-out f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Logout
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center" [ngStyle]="{ marginRight: !isDesktop ? '40px' : '' }">
      <div class="user_d f-13" *ngIf="!isNewVendorForERP && isDesktop">
        Card
        <span class="portal">
          <label class="switch">
            <input
              type="checkbox"
              [checked]="isTableView"
              (change)="onChangeUI($event.target.checked)"
            />
            <span class="slider round"></span>
          </label>
        </span>
        Table
      </div>
      <div class="short_name f-12">{{ name_short }}</div>
      <div *ngIf="!isMobile" class="f-12 mr-1">{{ userDetails.userdetails.firstName }}</div>
      <div *ngIf="!isMobile" (clickOutside)="onClickedOutside($event)">
        <!-- <mat-icon
          class="sign_out dropdown"
          svgIcon="logout"
          title="Sign Out"
          (click)="isActive()"
        ></mat-icon> -->
        <span class="material-icons sign_out dropdown"  
          title="Sign Out"
          (click)="isActive()">
          logout
          </span>
        <div
          [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
          class="dropdown-content"
        >
          <a (click)="openDialog()">Change Password</a>
          <a (click)="confirm_pop()">Logout</a>
        </div>
      </div>
    </div>

    <div *ngIf="!isDesktop" class="menuIcon" (click)="openMenu()">
      <span class="material-icons">
        {{ isMenuOpen ? "close" : "menu" }}
      </span>
    </div>
  </nav>
  <div *ngIf="isMenuOpen" class="mobile_menu">
    <div
      class="nav_Link f-14"
      [ngClass]="DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''"
      routerLinkActive="active_cls"
      [routerLink]="'home'"
    >
      Dashboard
    </div>

    <div
      class="nav_Link f-14"
      [ngClass]="DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''"
      routerLinkActive="active_cls"
      [routerLink]="'uploadInvoices'"
    >
      Upload
    </div>

    <div
      class="nav_Link f-14"
      [ngClass]="DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''"
      routerLinkActive="active_cls"
      [routerLink]="'invoice'"
    >
      Document Status
    </div>

    <div
      class="nav_Link f-14"
      [ngClass]="DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''"
      routerLinkActive="active_cls"
      [routerLink]="'ExceptionManagement'"
    >
      Exceptions
    </div>

    <div
      *ngIf="addUsersBoolean"
      class="nav_Link f-14"
      [ngClass]="DS.configData.client_name == 'Cenomi' ? 'cenomiNavlink' : ''"
      routerLinkActive="active_cls"
      [routerLink]="'vendorContacts'"
    >
      Contact Details
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        DS.configData.client_name == 'Cenomi'
          ? 'cenomiNavlink'
          : ''
      "
      (click)="openDialog()"
      *ngIf="isMobile"
    >
      Change Password
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        DS.configData.client_name == 'Cenomi'
          ? 'cenomiNavlink'
          : ''
      "
      (click)="confirm_pop()"
      *ngIf="isMobile"
    >
      Logout
    </div>
    <!-- <div class="navLink" data-text="Change Password" (click)="openDialog()">
            <i
              class="fa fa-lock f-18 ml-r-12"
              aria-hidden="true"
              routerLinkActive="ml-9"
            ></i>
            Change Password
          </div> -->
  </div>
  <section>
    <div *ngIf="!isNewVendorForERP">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="isNewVendorForERP">
      <app-profile-vendor></app-profile-vendor>
    </div>
  </section>

  <footer class="f-12">
    <!-- Copyrights@2024 -->
    <div>
      <span class="mr-3">{{DS.apiVersion}}</span>  
      <a href="static-content/DPA" target="_blank">{{isMobile? 'DPA':'Data Processing Agreement (DPA)'}}</a> | 
      <a href="static-content/privacy-policy" target="_blank">Privacy policy</a> | 
      <a href="mailto:help.serina@datasemantics.co">Help</a>
    </div>
  </footer>
</div>

<!-- Cnfirmation Dialog -->
<!-- <p-dialog
  header=" "
  [(visible)]="displayResponsivepopup"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="text-center">
    <div>
      <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i>
    </div>
    <div class="deleteDivText">
      {{ BtnText }}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">
        Yes
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        (click)="displayResponsivepopup = false"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog> -->
